import { RegisterUserProps, webHistoryPointUserParams } from "types/user";
import request from "./axiosService";

const URL_LOGIN = "/auth/login";
const URL_USER = "/user";
const URL_PROFILE = "/auth/profile";
const URL_CHANGE_PASSWORD = "/user/change-password";
const URL_DELETE_ACCOUNT = "user/delete";
const URL_SUPPORT_CONTACT = "/support-contact";
const URL_WEB_HISTORY_POINT_USER = "/points-user/web-history-point-user";
const URL_ACTIVE_USER_BY_OTP = "/user-of-shop/active-user-by-otp";
const URL_SEND_OTP_ACTIVE_USER = "/user-of-shop/send-otp-active-user";
const URL_RESET_PASSWORD_BY_EMAIL = "/user-of-shop/reset-password-by-email";
export function resetPasswordByEmailAPI(data: { email: string }) {
  return request({
    url: URL_RESET_PASSWORD_BY_EMAIL,
    method: "post",
    data,
  });
}
export function sendOtpActiveUserAPI(data: { email: string }) {
  return request({
    url: URL_SEND_OTP_ACTIVE_USER,
    method: "post",
    data,
  });
}
export function activeUserByOtpAPI(data: any) {
  return request({
    url: URL_ACTIVE_USER_BY_OTP,
    method: "post",
    data,
  });
}
export function registerUserAPI(data: RegisterUserProps) {
  return request({
    url: URL_USER,
    method: "post",
    data,
  });
}

export function getUserInfoAPI(token: string) {
  return request({
    url: URL_USER,
    method: "get",
    token,
  });
}

export function getProfileAPI(token: string) {
  return request({
    url: URL_PROFILE,
    method: "get",
    token,
  });
}

export function updateUserAPI(token: string, id: string, data: any) {
  return request({
    url: URL_USER + "/" + id,
    method: "put",
    token,
    data,
    isFormData: true,
  });
}
export function changePasswordAPI(token: string, data: any) {
  return request({
    url: URL_CHANGE_PASSWORD,
    method: "post",
    token,
    data,
  });
}

export function deleteAccountAPI(token: string, password: string) {
  return request({
    url: URL_DELETE_ACCOUNT,
    method: "put",
    token,
    data: { passwordCurrent: password },
  });
}
//NgocNa
export function supportContactAPI(data: any) {
  return request({
    url: URL_SUPPORT_CONTACT,
    method: "post",
    data,
  });
}
export function deleteUserAvatarAPI(token: string, id: string, data: any) {
  return request({
    url: URL_USER + "/" + id,
    method: "put",
    token,
    data,
  });
}
export function webHistoryPointUserAPI(params: webHistoryPointUserParams) {
  return request({
    url:
      URL_WEB_HISTORY_POINT_USER + `?type=${params.type}&user=${params.user}&date=${params.date}`,
    method: "get",
  });
}
