import type { TableProps } from "antd";
import { DatePicker, DatePickerProps, Table } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import { useAppDispatch, useAppSelector } from "app/hooks";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import {
  formatMoney,
  formatServerDateToDDMMYYYYHHMM,
  getCurrentDate,
  getCurrentDateYYYYMM,
} from "helpers";
import { getToken } from "helpers/Token";
import i18n from "locales/i18n";
import "moment/locale/ja";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getHistorySendGift } from "slices/paymentHistorySlice";
import { URL_UPLOAD_IMAGE } from "../../../constants/index";
import * as cts from "../../../constants/ui/svg";
interface DataType {
  key: any;
  time: any;
  productName: any;
  productDetail: any;
  total: any;
  method: any;
  image: any;
}
interface Props {}

const TabCoinHistory: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const userInfo = useAppSelector((state) => state.users.userInfo);
  const historySendGifts = useAppSelector((state) => state.paymentHistory.historySendGifts);
  const [dateFilter, setDateFilter] = useState("");
  const onChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    setDateFilter(dateString);
  };
  const columns: TableProps<DataType>["columns"] = [
    {
      title: (
        <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("DATE_N_TIME")}</span>
      ),
      dataIndex: "time",
      key: "time",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[150px] max-500px:justify-center max-500px:items-center">
          {text}
        </span>
      ),
    },
    {
      title: <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("ITEM")}</span>,
      dataIndex: "productName",
      key: "productName",
      render: (text, record: DataType) => (
        <span className="text-[#101828] text-[14px] notosans_normal flex items-center justify-center max-500px:flex max-500px:w-[150px] max-500px:justify-center max-500px:items-center">
          <img
            className="w-[24px] h-[24px] object-contain"
            alt=""
            src={`${URL_UPLOAD_IMAGE}/gifts/${record.image}`}
          />
          {text}
        </span>
      ),
    },
    {
      title: <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("QUANTITY")}</span>,
      dataIndex: "productDetail",
      key: "productDetail",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[100px] max-500px:justify-center max-500px:items-center">
          {text}
        </span>
      ),
    },
    {
      title: <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("CHANNEL")}</span>,
      key: "total",
      dataIndex: "total",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_normal max-500px:flex max-500px:w-[150px] max-500px:justify-center max-500px:items-center">
          {text}
        </span>
      ),
    },
    {
      title: (
        <span className="text-[#101828] text-[14px] notosans_bold">{i18n.t("COINS_USED")}</span>
      ),
      key: "method",
      dataIndex: "method",
      render: (text) => (
        <span className="text-[#101828] text-[14px] notosans_bold flex items-center justify-center w-full max-500px:flex max-500px:w-[100px] max-500px:justify-center max-500px:items-center">
          {formatMoney(text)}
          {cts.coinMini}
        </span>
      ),
    },
  ];
  // const data: DataType[] = [
  //   {
  //     key: "1",
  //     time: "02/12/2023 - 21:00",
  //     productName: "バルーン",
  //     productDetail: 10,
  //     total: "Jenny Crochet",
  //     method: 50,
  //   },
  //   {
  //     key: "2",
  //     time: "02/12/2023 - 21:00",
  //     productName: "帽子",
  //     productDetail: 5,
  //     total: "Virtual Idol",
  //     method: 50,
  //   },
  //   {
  //     key: "3",
  //     time: "01/12/2023 - 21:00",
  //     productName: "バルーン",
  //     productDetail: 2,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "4",
  //     time: "01/12/2023 - 21:00",
  //     productName: "ワインボトル",
  //     productDetail: 1,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "5",
  //     time: "01/12/2023 - 21:00",
  //     productName: "ワインボトル",
  //     productDetail: 1,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "6",
  //     time: "01/12/2023 - 21:00",
  //     productName: "バルーン",
  //     productDetail: 5,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "7",
  //     time: "01/12/2023 - 21:00",
  //     productName: "バルーン",
  //     productDetail: 3,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "8",
  //     time: "01/12/2023 - 21:00",
  //     productName: "バラ",
  //     productDetail: 6,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "9",
  //     time: "01/12/2023 - 21:00",
  //     productName: "バラ",
  //     productDetail: 1,
  //     total: "Miranda",
  //     method: 50,
  //   },
  //   {
  //     key: "10",
  //     time: "01/12/2023 - 21:00",
  //     productName: `バルーン`,
  //     productDetail: 1,
  //     total: "Miranda",
  //     method: 50,
  //   },
  // ];
  useEffect(() => {
    const token = getToken();
    if (token) {
      if (dateFilter.length === 7) {
        dispatch(
          getHistorySendGift({
            date: dateFilter + "-01",
            userSend: userInfo._id,
            token: token,
          })
        );
      }
    } else {
      navigate("/");
    }
  }, [dateFilter, dispatch, navigate, userInfo._id]);

  useEffect(() => {
    setDateFilter(getCurrentDateYYYYMM());
  }, []);
  const dataMemo = useMemo(() => {
    return historySendGifts
      .map((item: any, index: number) => {
        if (item?.gift && item?.userReceive) {
          return {
            key: index,
            time: formatServerDateToDDMMYYYYHHMM(item?.updatedAt),
            productName: item?.gift?.name,
            productDetail: item?.number,
            total: item?.userReceive?.nickName,
            method: item?.points * item?.number,
            image: item?.gift?.image,
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [historySendGifts]);

  useEffect(() => {
    setData(dataMemo);
  }, [dataMemo]);

  return (
    <div className="mb-[40px] min-h-[440px] bg-white rounded-[8px] p-[20px] flex flex-col max-500px:mx-0 max-500px:px-0 max-500px:py-3">
      <span className="text-[20px] notosans_bold text-[#101828] max-500px:hidden">
        {i18n.t("PAYMENT_HIS")}
      </span>
      <div className="flex items-center justify-between pb-[20px] mb-[20px] border-b border-[#EAECF0] max-500px:px-3 max-500px:border-none max-500px:pb-0 max-500px:mb-3">
        <span className="text-[#475467] text-[16px] notosans_normal mt-[8px] flex items-center ">
          {i18n.t("CURRENT_COIN")}
          <span className="text-[16px] notosans_bold mx-[5px]">
            {formatMoney(Number(userInfo?.points)) || 0}
          </span>
          {cts.coinMini}
        </span>
        <DatePicker
          allowClear={false}
          className="h-[44px] w-[116px] rounded-[8px]"
          onChange={onChange}
          defaultValue={dayjs(getCurrentDate(), "YYYY-MM-DD")}
          picker="month"
          suffixIcon={cts.calendarIcon}
          locale={locale}
        />
      </div>
      <Table
        locale={{
          emptyText: (
            <div className="text-textGray900 text-[16px] notosans_normal py-4 font-[600]">
              {i18n.t("NO_TRANSACTION")}
            </div>
          ),
        }}
        className="table_payment"
        columns={columns}
        dataSource={data ?? []}
      />
    </div>
  );
};
export default TabCoinHistory;
